export default [
    {
      path: "/payment",
      name: "payment",
      redirect: "/payment/payment-list",
      children: [
        {
          path: "payment-list", // relative path
          name: "payment-list",
          component: () => import("@/pages/payment/payment-list.vue")
        },
        {
          path: "payment-create", // relative path
          name: "payment-create",
          component: () => import("@/pages/payment/payment-create.vue")
        },
        {
          path: "payment-edit/:id", // relative path
          name: "payment-edit",
          component: () => import("@/pages/payment/payment-edit.vue"),
          props: true
        }
      ]
    },
  ];
  