<template>
    <div class="col-md-4">
        <div class="row">
            <div class="col-md-12" v-for="allDetail in allDetails" :key="allDetail">
                <div class="card small-widget">
                    <div :class="allDetail.cardClass">
                        <span class="f-light">{{ allDetail.title }}</span>
                        <div class="d-flex align-items-end gap-1">
                            <h4>{{ allDetail.dataInNumber }}</h4>
                            <span :class="allDetail.spanClass"><i :class="allDetail.iconClass"></i><span>{{
                                allDetail.status }}</span></span>
                        </div>
                        <div class="bg-gradient">
                            <svg class="stroke-icon svg-fill">
                                <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${allDetail.svgIcon}`">
                                </use>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            allDetails: [{
                cardClass: "card-body success",
                title: "Toplam Kâr",
                dataInNumber: "725.000₺",
                spanClass: "font-primary f-12 f-w-500",
                iconClass: "icon-arrow-up",
                status: "+80%",
                svgIcon: "profit"
            }]

        }
    },
}
</script>