export default [
    {
      path: "/product",
      name: "product",
      redirect: "/product/product-list",
      children: [
        {
          path: "product-list", // relative path
          name: "product-list",
          component: () => import("@/pages/product/product-list.vue")
        },
        {
          path: "product-create", // relative path
          name: "product-create",
          component: () => import("@/pages/product/product-create.vue")
        },
        {
          path: "product-edit/:id", // relative path
          name: "product-edit",
          component: () => import("@/pages/product/product-edit.vue"),
          props: true
        }
      ]
    },
  ];
  