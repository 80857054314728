export default [
  {
    path: "/dealer",
    name: "dealer",
    redirect: "/dealer/dealer-list",
    children: [
      {
        path: "dealer-list", // relative path
        name: "dealer-list",
        component: () => import("@/pages/dealer/dealer-list.vue")
      },
      {
        path: "dealer-create", // relative path
        name: "dealer-create",
        component: () => import("@/pages/dealer/dealer-create.vue")
      },
      {
        path: "dealer-edit/:id", // relative path
        name: "dealer-edit",
        component: () => import("@/pages/dealer/dealer-edit.vue"),
        props: true
      }
    ]
  },
];
