<template>
    <div class="left-header col-xxl-5 col-xl-6 col-lg-5 col-md-4 col-sm-3 p-0">
        <swiper :slidesPerView="1" :autoplay="{ delay: 2500, disableOnInteraction: false }" :modules="modules"
            class="notification-slider" direction="vertical">
            <swiper-slide class="h-100">
                <div class="d-flex h-100">
                    <img src="@/assets/images/giftools.gif" alt="gif">
                    <h6 class="mb-0 f-w-400">
                        <span class="f-light">İş Süreçlerinizi Kolaylaştırın, Verimliliği Artırın!</span>
                    </h6><i class="icon-arrow-top-right f-light"></i>
                </div>
            </swiper-slide>
            <swiper-slide class="h-100">
                <div class="d-flex h-100">
                    <img src="@/assets/images/giftools.gif" alt="gif">
                    <h6 class="mb-0 f-w-400">
                        <span class="f-light">Tüm Yönetim Araçları Bir Arada, Hemen Keşfedin! </span>
                    </h6><a class="ms-1" href="https://isiksoft.com.tr/" target="_blank">İletişime geçin !</a>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";

export default {

    components: {
        Swiper,
        SwiperSlide,
    },

    setup() {
        return {
            modules: [Autoplay],
        };
    },
}
</script>