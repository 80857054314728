<template>
  <head>
    <title>cuba-Home page</title>
  </head>
  <div class="loader-wrapper" v-if="loading">
    <div class="loader-index"><span></span></div>
    <svg>
      <defs></defs>
      <filter id="goo">
        <fegaussianblur in="SourceGraphic" stddeviation="11" result="blur"></fegaussianblur>
        <fecolormatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo"> </fecolormatrix>
      </filter>
    </svg>
  </div>
  <router-view v-else></router-view>
</template>

<script>
import { mapActions } from 'vuex';

export default {

  name: 'App',
  data() {
    return {
      loading: true,
    }
  },
  watch: {
    '$route'() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  },
  created() {
    console.log("App created");
    this.initAuth().then(() => {
      this.loading = false;
    }).catch(() => {
      this.loading = false;
    });
  },

  methods: {
    ...mapActions({
      initAuth: 'auth/initAuth',
    }),
  },
}
</script>

