import { appAxios } from "./appService";

export default {
    async getCategories() {
        const response = await appAxios.get("/pcategory/get");
        return response.data;
    },
    async createCategory(body) {
        const response = await appAxios.post("/pcategory/create", body);
        return response.data;
    },
    async getSubCategories(categoryId) {
        const response = await appAxios.get(`/pcategory/get/${categoryId}`);
        return response.data;
    },
    async getCategory(categoryId) {
        const response = await appAxios.get(`/pcategory/getById/${categoryId}`);
        return response.data;
    },
    async updateCategory(categoryId, body) {
        const response = await appAxios.put(`/pcategory/update/${categoryId}`, body);
        return response.data;
    },
    async deleteCategory(categoryId) {
        const response = await appAxios.delete(`/pcategory/delete/${categoryId}`);
        return response.data;
    },
    async getCategoryTree() {
        const response = await appAxios.get("/pcategory/getTree");
        return response.data;
    }

};