<template>
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-6" v-for="allDetail in allDetails" :key="allDetail">
                    <div class="card small-widget">
                        <div :class="allDetail.cardClass">
                            <span class="f-light">{{ allDetail.title }}</span>
                            <div class="d-flex align-items-end gap-1">
                                <h4>{{ allDetail.dataInNumber }}</h4>
                                <span :class="allDetail.spanClass"><i :class="allDetail.iconClass"></i><span>{{
                                    allDetail.status }}</span></span>
                            </div>
                            <div class="bg-gradient">
                                <svg class="stroke-icon svg-fill">
                                    <use
                                        :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${allDetail.svgIcon}`">
                                    </use>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import {
    allDetails
} from "../../../data/dashboard/default"
export default {
    data() {
        return {
            allDetails: allDetails
        }
    },
}
</script>