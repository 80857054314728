<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img class="b-r-10 app-bar-user-image" src="https://picsum.photos/200" alt="" />
      <div class="media-body">
        <span> {{ user.name }} {{ user.lastname }} </span>
        <p class="mb-0 font-roboto">
          Admin <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li>
        <router-link :to="'/users/edit/'+user.id"> <vue-feather type="user"></vue-feather> <span>Account </span> </router-link>
      </li>
      <li>
        <router-link :to="'/email/readMail'"><vue-feather type="mail"></vue-feather><span>Inbox</span></router-link>
      </li>
      <li>
        <router-link :to="'/app/task'"><vue-feather type="file-text"></vue-feather><span>Taskboard</span></router-link>
      </li>
      <li>
        <router-link :to="'/pages/search'"><vue-feather
            type="settings"></vue-feather><span>Settings</span></router-link>
      </li>
      <li>
        <a @click="logout"><vue-feather type="log-in"></vue-feather><span> Çıkış Yap </span></a>
      </li>
    </ul>
  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Profile',
  methods: {
    ...mapActions({
      doSignOut: 'auth/doSignOut',
    }),

    logout: function () {
      this.doSignOut();
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    })
  },
};
</script>
