import { appAxios } from "./appService";

export default {
    async getPayments() {
        const response = await appAxios.get("/payment/list");
        return response.data;
    },
    async createPayment(body) {
        const response = await appAxios.post("/payment/create", body);
        return response.data;
    },
    async getPaymentById(id) {
        const response = await appAxios.get(`/payment/detail/${id}`);
        return response.data;
    },
    async updatePayment(paymentId, body) {
        const response = await appAxios.put(`/payment/update/${paymentId}`, body);
        return response.data;
    },
    async deletePayment(paymentId) {
        const response = await appAxios.delete(`/payment/delete/${paymentId}`);
        return response.data;
    }
};