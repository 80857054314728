import authService from "@/services/authService";
import router from "@/router";
import { notificationError, notificationSuccess } from '@/utils/notification';

export default {
  namespaced: true,
  state: {
    isAuthenticated: false,
    user: null,
    updateUserStatus: null,
    loading: true,
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    getUser: (state) => state.user,
    loading: (state) => state.loading
  },
  mutations: {
    SET_IS_AUTHENTICATED(state, payload) {
      state.isAuthenticated = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_LOADING(state, payload) {
      setTimeout(() => {
        state.loading = payload;
      }, 1000);
    },
  },
  actions: {
    async doSignIn({ commit }, payload) {
      try {
        const data = {
          username: payload.username,
          password: payload.password,
        };

        const response = await authService.login(data);

        if (response.status === "success" && response.data.token) {
          localStorage.setItem("auth-token", response.data.token);
          commit("SET_IS_AUTHENTICATED", true);
          commit("SET_USER", response.data.user);
          router.push("/");
        }

        return response;
      } catch (error) {
        notificationError("Kullanıcı adı veya şifre hatalı");
        return error.response;
      }
    },

    async initAuth({ commit }) {
      try {
        const token = localStorage.getItem("auth-token");

        if (!token) {
          return null;
        }

        const response = await authService.verifyToken();

        if (response.status === "success") {
          commit("SET_USER", response.user);
          commit("SET_IS_AUTHENTICATED", true);

          const redirectPath = router.currentRoute.value.query.redirect || "/";
          router.push(redirectPath);
        }

        return response.data;
      } catch (error) {
        return error.response;
      }
    },

    async doSignOut({ commit }) {
      try {
        commit("SET_IS_AUTHENTICATED", false);
        localStorage.removeItem("auth-token");
        router.push({ name: "login" });
      } catch {
        return null;
      }
    },

    async doSignUp(_, payload) {
      try {

        const body = {
          name: payload.name,
          lastname: payload.lastname,
          email: payload.email,
          phone: payload.phone,
          username: payload.username,
          password: payload.password,
        };

        const response = await authService.register(body);

        if (response.status !== "success") {
          notificationError(response.data.message);
          return;
        }

        return response.data;
      } catch (error) {
        console.log("error", error);
        notificationError(error.response?.data?.error?.message);
      }
    },

    async updatePermissions(_, payload) {
      try {
        const body = {
          permissions: payload.permissions
        }
        const response = await authService.updatePermissions(body, payload.userId);

        return response;
      } catch (error) {
        console.log("error", error);
        notificationError(error.response?.data?.error?.message);
        return error.response;
      }
    },

    async changeUserStatus(_, payload) {
      try {

        const body = {
          status: !payload.status,
        }

        const response = await authService.changeUserStatus(body, payload.id);

        if (response.status === "success") {
          notificationSuccess("Kullanıcı durumu başarıyla güncellendi");
        }

        return response;
      } catch (error) {
        console.log("error", error);
        notificationError(error.response?.data?.error?.message);
        return error.response;
      }
    },

    async getUserInfos({ commit }, userId) {
      commit("SET_LOADING", true);
      try {
        const response = await authService.getUserInfos(userId);
        commit("SET_LOADING", false);
        return response
      } catch (error) {
        commit("SET_LOADING", false);
        console.log("error", error);
        notificationError(error.response?.data?.error?.message);
      }
    },

    async updateUserInfos({ dispatch }, form) {
      try {
        const body = {
          name: form.name,
          lastname: form.lastname,
          email: form.email,
          phone: form.phone || null,
          username: form.username || null,
          status: form.status || null,
          image: form.image || null,
        };

        if (form.upassword !== undefined && form.upassword !== "") {
          body.password = form.upassword;
        }

        const response = await authService.updateUser(form.id, body);
    
        if (response.status === "success") {
          notificationSuccess("Kullanıcı bilgileri başarıyla güncellendi");
    
          if (form.dealer === undefined) {
            await dispatch("doSignOut"); 
            notificationSuccess("Çıkış yapıldı, tekrar giriş yapınız");
          }
        }
      } catch (error) {
        console.log("error", error);
        notificationError(error.response?.data?.error?.message);
      }
    },
    
  }
};