import axios from "axios";
// import nProgress from "nprogress";
export const appAxios = axios.create({
  // baseURL: "http://127.0.0.1:8000/api/",
  baseURL: "https://seahorse-app-76dnf.ondigitalocean.app/api/",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("auth-token"),
  },
});

appAxios.interceptors.request.use((config) => {
  config.headers.Authorization =
    "Bearer " + localStorage.getItem("auth-token");
//   nProgress.start();
  return config;
});

appAxios.interceptors.response.use((response) => {
//   nProgress.done();
  return response;
});

appAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    // nProgress.done();
    if (error.response.status === 401) {
      localStorage.removeItem("auth-token");
    }
    return Promise.reject(error);
  }
);