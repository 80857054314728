import categoryService from "@/services/categoryService";
import { notificationError, notificationSuccess, notificationInfo } from '@/utils/notification';
import router from "@/router";

export default {
  namespaced: true,
  state: {
    categories: [],
    parentCategory: null,
  },
  getters: {
    getCategories: (state) => state.categories,
    getParentCategory: (state) => state.parentCategory,
  },
  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    SET_PARENT_CATEGORY_ID(state, parentCategory) {
      state.parentCategory = parentCategory;
    },
    REMOVE_CATEGORY(state, categoryId) {
      state.categories = state.categories.filter(category => category.id !== categoryId);
    }
  },
  actions: {
    async initCategories({ commit }) {
      try {
        const response = await categoryService.getCategories();

        commit("SET_CATEGORIES", response.data);
      } catch (error) {
        console.error(error);
      }
    },
    async createCategory(_, form) {
      try {

        const body = {
          parentId: form.parentId || null,
          name: form.name,
          description: form.description,
          keyWords: form.keyWords,
          image: form.image,
        };

        const response = await categoryService.createCategory(body);

        if (response.status !== "success") {
          notificationError(response.data.message);
          return;
        }

        notificationSuccess("Kategori başarıyla oluşturuldu");

        router.push({ name: "category-list" });

      } catch (error) {
        notificationError(error.response?.data?.error?.message);
      }
    },

    async getSubCategories({ commit }, payload) {
      try {

        const categoryId = payload.id;
        const notification = payload.notification;

        const response = await categoryService.getSubCategories(categoryId);

        if (response.status !== "success") {
          notificationError(response.data.message);
        }

        if (response.data.subcategories.length > 0) {
          commit("SET_CATEGORIES", response.data.subcategories);
          commit("SET_PARENT_CATEGORY_ID", response.data.category);
        } else {
          notification && notificationInfo("Alt kategori bulunamadı");
        }
      } catch (error) {
        console.error(error);
        notificationError(error.response?.data?.error?.message);
      }
    },

    async getCategoryById(_, id) {
      try {

        console.log("id", id);
        

        const response = await categoryService.getCategory(id);

        if (response.status !== "success") {
          notificationError(response.message);
        }

        return response.data;
      } catch (error) {
        console.error(error);
        notificationError(error.response?.data?.error?.message);
      }
    },

    async updateCategory(_, form) {
      try {

        const body = {
          name: form.name,
          description: form.description,
          keyWords: form.keyWords,
          image: form.image,
          parentId: form.parentId || null,
        };

        const response = await categoryService.updateCategory(form.id, body);

        if (response.status !== "success") {
          notificationError(response.message);
          return;
        }

        notificationSuccess("Kategori başarıyla güncellendi");

        router.push({ name: "category-list" });

      } catch (error) {
        notificationError(error.response?.data?.error?.message);
      }

    },

    async deleteCategory({ commit }, id) {
      try {

        const response = await categoryService.deleteCategory(id);

        if (response.status !== "success") {
          notificationError(response.message);
          return;
        }

        notificationSuccess("Kategori başarıyla silindi");

        commit("REMOVE_CATEGORY", id);
      } catch (error) {
        notificationError(error.response?.data?.error?.message);
      }
    },

    async getCategoryTree() {
      try {
        
        const respons = await categoryService.getCategoryTree();

        if (respons.status !== "success") {
          notificationError(respons.message);
        }

        return respons.data;

      } catch (error) {
        console.error(error);
        notificationError(error.response?.data?.error?.message);
      }
    }

  },
};