import dealerService from "@/services/dealerService";
// import router from "@/router";
import { notificationError } from '@/utils/notification';

export default {
  namespaced: true,
  state: {
    dealers: [],
  },
  getters: {
    getDealers: (state) => state.dealers
  },
  mutations: {
    SET_DEALERS(state, dealers) {
      state.dealers = dealers;
    }
  },
  actions: {
    async initDealers({ commit }) {
      try {
        const response = await dealerService.getDealers();

        commit("SET_DEALERS", response.data);
      } catch (error) {
        console.error(error);
      }
    },
    async createDealer(_, payload) {
      try {

        const body = {
          title: payload.title,
          idNumber: payload.idNumber,
          taxOffice: payload.taxOffice,
          taxNumber: payload.taxNumber,
          address: payload.address,
          description: payload.description,
          discountRate: payload.discountRate,
          dueDate: payload.dueDate,
          riskLimit: payload.riskLimit,
        };

        const response = await dealerService.createDealer(body, payload.userId);

        if (response.status !== "success") {
          notificationError(response.data.message);
        }

        return response;
      } catch (error) {
        notificationError(error.response?.data?.error?.message);
      }
    },
    async getDealersForSelect() {
      try {
        const response = await dealerService.getDealersForSelect();
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getDealerById(_, dealerId) {
      try {
        const response = await dealerService.getDealerById(dealerId);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    }
  },
};