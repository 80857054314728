import { appAxios } from "./appService";

export default {
  async getDealers() {
    const response = await appAxios.get("/dealer/get");
    return response.data;
  },
  async createDealer(body, userId) {
    const response = await appAxios.post(`/dealer/create/${userId}`, body);
    return response.data;
  },
  async getDealersForSelect() {
    const response = await appAxios.get("/dealer/select");
    return response.data;
  },
  async getDealerById(id) {
    const response = await appAxios.get(`/dealer/get/${id}`);
    return response.data;
  }
};