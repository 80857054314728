import { appAxios } from "./appService";

export default {
    async getAllProducts() {
        const response = await appAxios.get("/product/get-all");
        return response.data;
    },
    async createProduct(product) {
        const response = await appAxios.post("/product/create", product);
        return response.data;
    },
    async getProductById(id) {
        const response = await appAxios.get(`/product/product/${id}`);
        return response.data;
    },
    async updateProduct(productId, product) {
        const response = await appAxios.put(`/product/update/${productId}`, product);
        return response.data;
    },
    async deleteProduct(productId) {
        const response = await appAxios.delete(`/product/delete/${productId}`);
        return response.data;
    }
};