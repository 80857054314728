export const allDetails = [{
    cardClass: "card-body primary",
    title: "Yeni Siparişler",
    dataInNumber: "2.908",
    spanClass: "font-primary f-12 f-w-500",
    iconClass: "icon-arrow-up",
    status: "+20%",
    svgIcon: "new-order"
},
{
    cardClass: "card-body warning",
    title: "Yeni Müşteriler",
    dataInNumber: "2.435",
    spanClass: "font-warning f-12 f-w-500",
    iconClass: "icon-arrow-up",
    status: "+50%",
    svgIcon: "customers"
},
{
    cardClass: "card-body secondary",
    title: "Ortalama Satış",
    dataInNumber: "₺389k",
    spanClass: "font-secondary f-12 f-w-500",
    iconClass: "icon-arrow-down",
    status: "-10%",
    svgIcon: "sale"
},
{
    cardClass: "card-body success",
    title: "Brüt Kâr",
    dataInNumber: "₺3.908",
    spanClass: "font-primary f-12 f-w-500",
    iconClass: "icon-arrow-up",
    status: "+80%",
    svgIcon: "profit"
}]


export const valuableCustomer = [{
    name: "Ahmet Yılmaz",
    email: "ahmet.yilmaz@gmail.com",
    image: require("@/assets/images/dashboard/user/1.jpg"),
},
{
    name: "Meryem Kaya",
    email: "meryem.kaya@gmail.com",
    image: require("@/assets/images/dashboard/user/2.jpg"),
},
{
    name: "Mehmet Demir",
    email: "mehmet.demir@gmail.com",
    image: require("@/assets/images/dashboard/user/6.jpg"),
},
{
    name: "Elif Aydın",
    email: "elif.aydin@gmail.com",
    image: require("@/assets/images/dashboard/user/5.jpg"),
},
{
    name: "Fatma Çelik",
    email: "fatma.celik@gmail.com",
    image: require("@/assets/images/dashboard/user/3.jpg"),
}]

export const orderOverview = [{
    svgIcon: "orders",
    title: "Siparişler",
    overviewInNumber: "10.098"
},
{
    svgIcon: "expense",
    title: "Gelir",
    overviewInNumber: "$12.678"
},
{
    svgIcon: "doller-return",
    title: "İadeler",
    overviewInNumber: "3.001"
}]


export const topcategories = [{
    image: require("@/assets/images/dashboard-2/category/1.png"),
    name: "Yiyecek & İçecek",
    orders: "12.200"
}, {
    image: require("@/assets/images/dashboard-2/category/2.png"),
    name: "Mobilya",
    orders: "7.510"
}, {
    image: require("@/assets/images/dashboard-2/category/3.png"),
    name: "Market Ürünleri",
    orders: "15.475"
}, {
    image: require("@/assets/images/dashboard-2/category/4.png"),
    name: "Elektronik",
    orders: "27.840"
}, {
    image: require("@/assets/images/dashboard-2/category/5.png"),
    name: "Oyuncaklar & Oyunlar",
    orders: "8.788"
}, {
    image: require("@/assets/images/dashboard-2/category/6.png"),
    name: "Masaüstü Bilgisayarlar",
    orders: "10.673"
}, {
    image: require("@/assets/images/dashboard-2/category/7.png"),
    name: "Cep Telefonu & Aksesuarları",
    orders: "5.129"
}]


export const activities = [{
    class: "activity-dot-warning",
    date: "2 Eylül, Bugün",
    title: "Ayşe bu ürünü yorumladı",
    decription: "Şeylerin sahnelenmesini veya karmaşık olmasını sevmiyorum."
}, {
    class: "activity-dot-secondary",
    date: "3 Eylül, 2022",
    title: "Mehmet Satın Alım Yaptı",
    decription: "Moda geçer, sadece stil aynı kalır."
}, {
    class: "activity-dot-success",
    date: "2 Eylül, Bugün",
    title: "Ayşe bu ürünü yorumladı",
    decription: "Gerçekçi olmayı severim. Şeylerin sahnelenmesini sevmiyorum."
}]

export const products =[{
    image: require("@/assets/images/dashboard-2/product/1.png")
}, {
    image: require("@/assets/images/dashboard-2/product/2.png")
},
{
    image: require("@/assets/images/dashboard-2/product/3.png")
}
]