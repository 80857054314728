<template>
  <div>

    <div class="container-fluid">
      <div class="row ">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img class="img-fluid for-light custom-logo-style" src="../assets/images/logo/logo.png" alt="looginpage" />
                  <img class="img-fluid for-dark custom-logo-style" src="../assets/images/logo/logo_dark.png" alt="looginpage" />
                </a>
              </div>
              <div class="login-main">
                <form class="theme-form">
                  <h4>Hesaba giriş yap</h4>
                  <p>Giriş yapmak için kullanıcı adınızı ve şifrenizi girin.</p>
                  <div class="form-group">
                    <label class="col-form-label">Kullanıcı Adı</label>
                    <input class="form-control" type="text" required="" placeholder="Test@gmail.com"
                      v-model="user.username.value">
                    <span class="validate-error" v-if="!user.username.value">{{
                      user.username.errormsg }}</span>

                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Şifre</label>
                    <div class="form-input position-relative">
                      <input class="form-control" type="password" name="login[password]" required=""
                        placeholder="*********" v-model="user.password.value">
                      <span class="validate-error" v-if="user.password.value.length < 7">{{ user.password.errormsg
                        }}</span>

                      <div class="show-hide"><span class="show"> </span></div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                      <input id="checkbox1" type="checkbox">
                      <label class="text-muted" for="checkbox1">Şifreyi Hatırla</label>
                    </div><router-link class="link" to="/authentication/forgetpassword"> Şifrenizi mi
                      unuttunuz?</router-link>
                    <div class="text-end mt-3">
                      <button class="btn btn-primary btn-block w-100" type="submit" @click.prevent="login">Giriş
                        yap</button>

                    </div>
                  </div>
                  <!-- <h6 class="text-muted mt-4 or">Or Sign in with</h6>
                  <div class="social mt-4">
                    <div class="btn-showcase"><a class="btn btn-light" href="https://www.linkedin.com/login"
                        target="_blank"><i class="txt-linkedin" data-feather="linkedin"></i> LinkedIn </a><a
                        class="btn btn-light" href="https://twitter.com/login?lang=en" target="_blank"><i
                          class="txt-twitter" data-feather="twitter"></i>twitter</a><a class="btn btn-light"
                        href="https://www.facebook.com/" target="_blank"><i class="txt-fb"
                          data-feather="facebook"></i>facebook</a></div>
                  </div>
                  <p class="mt-4 mb-0 text-center">Don't have account?<a class="ms-2" href="sign-up.html">Create
                      Account</a></p> -->
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import { toast } from 'vue3-toastify';

export default {
  name: 'login',
  data() {
    return {

      result: { username: '', password: '' },

      user: {
        username: {
          value: 'omer',
          errormsg: ''
        },
        password: {
          value: '123456',
          errormsg: ''
        }
      }
    };
  },

  methods: {
    ...mapActions({
      doSignIn: "auth/doSignIn",
    }),

    login() {
      if (!this.user.password.value || this.user.password.value.length < 7) {
        this.user.password.errormsg = 'min length 7'
      } else { this.user.password.errormsg = '' }

      if (!this.user.username.value) {
        this.user.username.errormsg = 'empty not allowed'
      }

      this.doSignIn({
        username: this.user.username.value,
        password: this.user.password.value,
      })
        .then(() => {
          
        })
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  },
};
</script>
