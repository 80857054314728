
/* eslint-disable */
import config from '../../data/layout.json';


const state = {
	layout: config,
	sidebarType: localStorage.getItem('SidebarType') || 'default',
	boxlayout: true,
	footer: '',
	svg: 'stroke-svg',
	sidebar: 'compact-sidebar1'
};


const getters = {
	footer: (state) => {
		return state.footer
	},
	sidebar: (state) => {
		return state.sidebar

	},
};

export default {
	namespaced: true,
	state,
	getters
};
