import productService from "@/services/productService";
import { notificationError, notificationSuccess, notificationInfo } from '@/utils/notification';
import router from "@/router";

const state = {
	products: []
};

const getters = {
	getProducts: state => {
		return state.products;
	}
};

const mutations = {
	SET_PRODUCTS: (state, products) => {
		state.products = products;
	},
	REMOVE_PRODUCT: (state, productId) => {
		state.products = state.products.filter(product => product.id !== productId);
	}
};

const actions = {
	async initProducts({ commit }) {
		try {
			const response = await productService.getAllProducts();

			commit('SET_PRODUCTS', response.data);
		} catch (error) {
			console.error(error);
		}
	},

	async getProductById(_, id) {
		try {
			const response = await productService.getProductById(id);
			return response.data;
		} catch (error) {
			console.error('Error during product fetch:', error);
			notificationError("Ürün detayları getirilirken bir hata oluştu.");
		}
	},

	async createProduct(_, product) {
		const {
			name,
			description,
			brand,
			unit,
			barcode,
			purchasePrice,
			salePrice,
			currency,
			stock,
			minOrderQuantity,
			vatRate,
			exciseTaxRate,
			keyWords,
			image,
			categories
		} = product;

		const body = {
			name,
			description,
			brand,
			unit,
			barcode,
			purchasePrice,
			salePrice,
			currency,
			stock,
			minOrderQuantity,
			vatRate,
			exciseTaxRate,
			keyWords,
			image,
			categories
		};

		try {
			const response = await productService.createProduct(body);

			if (response.status !== "success") {
				return notificationError(response.message || "Ürün eklenirken bir hata oluştu.");
			}

			notificationSuccess("Ürün başarıyla eklendi.");
			router.push({ name: 'product-list' });
		} catch (error) {
			console.error('Error during product creation:', error);
			notificationError("Ürün eklenirken bir hata oluştu.");
		}
	},

	async updateProduct(_, { id, form }) {
		try {

			const {
				name,
				description,
				brand,
				unit,
				barcode,
				purchasePrice,
				salePrice,
				currency,
				stock,
				minOrderQuantity,
				vatRate,
				exciseTaxRate,
				keyWords,
				categories,
				image
			} = form;
	
			const body = {
				name,
				description,
				brand,
				unit,
				barcode,
				purchasePrice,
				salePrice,
				currency,
				stock,
				minOrderQuantity,
				vatRate,
				exciseTaxRate,
				keyWords,
				categories,
				image
			};

			const response = await productService.updateProduct(id, body);

			if (response.status !== "success") {
				return notificationError(response.message || "Ürün güncellenirken bir hata oluştu.");
			}

			notificationSuccess("Ürün başarıyla güncellendi.");
			router.push({ name: 'product-list' });

		} catch (error) {
			console.error('Error during product update:', error);
			notificationError("Ürün güncellenirken bir hata oluştu.");
		}
	},

	async deleteProduct({ commit }, id) {
		try {
			const response = await productService.deleteProduct(id);

			if (response.status !== "success") {
				return notificationError(response.message || "Ürün silinirken bir hata oluştu.");
			}

			notificationSuccess("Ürün başarıyla silindi.");
			
			commit("REMOVE_PRODUCT", id);

		} catch (error) {
			console.error('Error during product deletion:', error);
			notificationError("Ürün silinirken bir hata oluştu.");
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};