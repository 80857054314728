import { appAxios } from "./appService";

export default {
  async register(body) {
    const response = await appAxios.post("/auth/register", body);
    return response.data;
  },

  async login(payload) {
    const body = {
      username: payload.username,
      password: payload.password,
    };

    const response = await appAxios.post("/auth/login", body);
    return response.data;
  },

  async verifyToken() {
    const response = await appAxios.get("/auth/verify-token");
    return response.data;
  },

  async updatePermissions(body, userId) {
    const response = await appAxios.put(`/auth/update-permissions/${userId}`, body);
    return response.data;
  },

  async changeUserStatus(body, userId) {
    const response = await appAxios.put(`/auth/change-status/${userId}`, body);
    return response.data;
  },

  async getUserInfos(userId) {
    const response = await appAxios.get(`/auth/user/${userId}`);
    return response.data;
  },

  async updateUser(userId, body) {
    const response = await appAxios.put(`/auth/update-user/${userId}`, body);
    return response.data;
  }
};