import paymentService from "@/services/paymentService";
import router from "@/router";
import { notificationError, notificationSuccess } from '@/utils/notification';

export default {
    namespaced: true,
    state: {
        payments: [],
    },
    getters: {
        getPayments: (state) => state.payments
    },
    mutations: {
        SET_PAYMENT(state, payments) {
            state.payments = payments;
        },
        REMOVE_PAYMENT(state, paymentId) {
            state.payments = state.payments.filter(payment => payment.id !== paymentId);
        }
    },
    actions: {
        async getPayments({ commit }) {
            try {
                const response = await paymentService.getPayments();

                commit("SET_PAYMENT", response.data);
            } catch (error) {
                console.error(error);
            }
        },

        async getPaymentById(_, id) {
            try {
                const response = await paymentService.getPaymentById(id);

                return response.data;
            } catch (error) {
                console.error(error);
            }
        },

        async createPayment(_, form) {
            try {

                const body = {
                    type: form.type,
                    amount: form.amount,
                    status: form.status,
                    payment_method: form.payment_method,
                    payer_name: form.payer_name,
                    payment_date: form.payment_date,
                    due_date: form.due_date || null,
                    reference_number: form.reference_number,
                    transaction_id: form.transaction_id,
                    dealerId: form.dealerId,
                };

                const response = await paymentService.createPayment(body);

                if (response.status !== "success") {
                    notificationError(response.message);
                    
                }

                notificationSuccess("Ödeme başarıyla oluşturuldu.");
                router.push({ name: 'payment-list' });
            } catch (error) {
                notificationError(error.response?.data?.error?.message);
            }
        },
        async updatePayment(_, form) {
            try {

                const body = {
                    type: form.type,
                    amount: form.amount,
                    status: form.status,
                    payment_method: form.payment_method,
                    payer_name: form.payer_name,
                    payment_date: form.payment_date,
                    due_date: form.due_date,
                    reference_number: form.reference_number,
                    transaction_id: form.transaction_id,
                    dealerId: form.dealerId,
                };

                const response = await paymentService.updatePayment(form.id, body);

                if (response.status !== "success") {
                    notificationError(response.message);
                }

                notificationSuccess("Ödeme başarıyla güncellendi.");
                router.push({ name: 'payment-list' });
            } catch (error) {
                notificationError(error.response?.data?.error?.message);
            }
        },
        async deletePayment({commit}, id) {
            try {
                const response = await paymentService.deletePayment(id);

                if (response.status !== "success") {
                    notificationError(response.message);
                }

                notificationSuccess("Ödeme başarıyla silindi.");

                commit("REMOVE_PAYMENT", id);

                router.push({ name: 'payment-list' });
            } catch (error) {
                notificationError(error.response?.data?.error?.message);
            }
        }
    },
};