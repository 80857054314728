<template>
    <div>
        <Breadcrumbs main="Dashboard" title="Ana Sayfa" />
        <div class="container-fluid">
            <div class="row size-column">
                <div class="col-xxl-10 col-md-12 box-col-8 grid-ed-12">
                    <div class="row">
                        <AllFourDeatilsCard />
                        <DetailCards />
                        <OrderthismonthCard />
                        <MonthlyProfitsCard />
                        <ValuableCustomerCard />
                        <OrderOverviewCard />
                        <VisitorsCard />
                        <RecetOrderCard />

                    </div>
                </div>
                <div class="
                col-xxl-2 col-xl-3 col-md-4
                grid-ed-none
                box-col-4e
                d-xxl-block d-none
              ">
                    <TopCategoriesCard />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AllFourDeatilsCard from "./default/AllFourDeatilsCard.vue"
import DetailCards from "./default/DetailCards.vue"
import ValuableCustomerCard from "./default/ValuableCustomerCard.vue"
import OrderthismonthCard from "./default/OrderthismonthCard.vue"
import MonthlyProfitsCard from "./default/MonthlyProfitsCard.vue"
import OrderOverviewCard from "./default/OrderOverviewCard.vue"
import VisitorsCard from "./default/VisitorsCard.vue"
import RecetOrderCard from "./default/RecetOrderCard.vue"
import TopCategoriesCard from "./default/TopCategoriesCard.vue"
import {
    ecomDashboard
} from "../../data/comon";
export default {
    components: {
        AllFourDeatilsCard,
        DetailCards,
        ValuableCustomerCard,
        OrderthismonthCard,
        MonthlyProfitsCard,
        OrderOverviewCard,
        VisitorsCard,
        RecetOrderCard,
        TopCategoriesCard,

    },
    data() {
        return {
            ecomDashboard: ecomDashboard,
        };
    },
};
</script>