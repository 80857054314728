import { toast } from 'vue3-toastify';

export function notificationSuccess(message) {
    toast.success(message, {
        theme: 'colored',
        icon: false,
        hideProgressBar: true,
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
    });
}

export function notificationError(message) {
    toast.error(message, {
        theme: 'colored',
        icon: false,
        hideProgressBar: true,
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
    });
}

export function notificationInfo(message) {
    toast.info(message, {
        theme: 'colored',
        icon: false,
        hideProgressBar: true,
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
    });
}