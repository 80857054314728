export default [
    {
      path: "/category",
      name: "category",
      redirect: "/category/category-list",
      children: [
        {
          path: "category-list", // relative path
          name: "category-list",
          component: () => import("@/pages/category/category-list.vue")
        },
        {
          path: "category-create", // relative path
          name: "category-create",
          component: () => import("@/pages/category/category-create.vue")
        },
        {
          path: "category-edit/:id", // relative path
          name: "category-edit",
          component: () => import("@/pages/category/category-edit.vue"),
          props: true
        }
      ]
    },
  ];
  