export default [
    {
        path: '/users',
        name: 'users',
        children: [
            {
                path: "profile",
                name: "profile",
                component: () => import("@/pages/users/profile/userProfile.vue")
            },
            {
                path: "edit/:id",
                name: "edit",
                component: () => import("@/pages/users/edit/userEdit.vue"),
                props: true,
            },
            {
                path: "cards",
                name: "cards",
                component: () => import("@/pages/users/cards/userCards.vue")
            }
          
        ]
    },
];